import React, {useState} from 'react';
import $ from 'jquery';

//阅读搜索页面的URL信息转化成array
function readLocation(){
    const advancedArr = {};
    const advancedMap = [
        "printed_name",
        "colorw",
        "coloru",
        "colorb",
        "colorr",
        "colorg",
        "colorc",
        "colorparam1",
        "colorparam2",
        "colorparam3",
        "subtype",
        "setcode",
        "cmc",
        "rarity0",
        "rarity1",
        "rarity2",
        "rarity3",
        "rarity4",
        "rule_cn",
        "power",
        "toughness",
        "artist",
        "format",
    ]
    const infstr = window.location.href.split("?")[1].split("&");
    //将URL信息转化成array
    for (var i = 0; i < infstr.length; i++) {
        var x = infstr[i].split("=");
        if(advancedMap.indexOf(x[0])==-1){continue}
        advancedArr[x[0]] = x[1];
    };
    return advancedArr
};

//获取对应名称单卡的列表和简要信息
//api for "https://wxapi.mtgso.cn/opensearch/demo/demo_search.php"
//读取location中的信息
async function getCardSearchbyName(cardName){
    // ?printed_name=" + cardName;
    const link = "https://wxapi.mtgso.cn/opensearch/demo/demo_search.php";
    const advancedArr = readLocation();
    const printed_name = cardName||advancedArr.printed_name;
    const req = await $.ajax({
        url: link,
        method: 'GET',
        data:{
            printed_name : printed_name,
        }
    })
    return req;
}

//给定高级搜索信息，获取单卡list
async function getCardSearchWithAdvanced(advancedArr){
    const link = "https://wxapi.mtgso.cn/opensearch/demo/demo_search.php";
    return $.ajax({
        url: link,
        method: 'GET',
        data:{
            ...advancedArr
        }
    })
}

//获取给定id单卡的详细信息
//api for "https://www.mtgso.cn/wxapi_2/card_2020-02-12.php" 
async function getSingleCardDetail(card_id){
    // ?id=" + card_id
    const link = 'https://www.mtgso.cn/wxapi_2/card_2020-02-12.php';
    const req =  await $.ajax({
        url: link, 
        method: 'GET',
        data:{
            id : card_id,
        }
    });
    console.log(readSingleCardDetail(req));
    return readSingleCardDetail(req);
}
    //读取 "https://www.mtgso.cn/wxapi_2/card_2020-02-12.php" 的返回信息
    //designed to read card detail from 'https://www.mtgso.cn/wxapi_2/card_2020-02-12.php';
    function readSingleCardDetail(arr){
        //返回dict，key为系列字母缩写，value为对应单卡id和collector number
        function countVersion(array){
            const set_collection = {};
            for (let i = 0; i < arr.length; i++) {
                var set = arr[i].set;
                set_collection[set]?(
                        set_collection[set].push({
                            id:arr[i]["id"],
                            collector_number: arr[i]["collector_number"]
                        })
                    ):(
                        set_collection[set] = [{
                            id:arr[i]["id"],
                            collector_number: arr[i]["collector_number"]
                        }]
                    );
            }
            return set_collection;
        }
        const current_card = {};
        current_card["card"] = arr[0];
        current_card["info-id"] = countVersion(arr[3]);

        return current_card;
    }


//api for opensearch-autocomplete
async function getCardnameAssociate(cardName, boxPageNum){
    const link = '';
    var result = await $.ajax({
        url:'https://www.mtgso.cn/api', 
        method: 'POST',
        data: JSON.stringify({
            "api": "1.0",
            "method": "opensearch-autocomplete",
            "version": "1.0",
            "payload": {
                text : String(cardName),
                page : boxPageNum || 0
            }
        }),
    })
    return result;
}

export{getCardSearchbyName, getSingleCardDetail, getCardnameAssociate, getCardSearchWithAdvanced}