import React, {useState} from 'react';
import { Spin, Space} from 'antd';
import $ from 'jquery';
import {UserOutlined, SearchOutlined, CloseCircleOutlined} from '@ant-design/icons';
import 'antd/dist/antd.css';

import {getCardSearchbyName, getCardnameAssociate} from '../../js/singles-search-display/getSearchSingleCard';
import '../../css/headbar-footbar.css';
import '../../css/center-layout.css';

class CenterLayoutHeader extends React.Component{
	render(){
		return(
			<header id="navigation-header">
				<div className="left-navi-container">
					<nav id="site-navigation">
						<a id="deck-link" href="">套牌</a>
					</nav>
				</div>
				<div className="right-navi-container">
					<UserCenterBox />
				</div>
			</header>
		)
	}
}

class CenterLayoutFooter extends React.Component{
	render(){
		return(
			<div id="bottom-nav">
  			<div className="left-navi-container flex-col">
	  			<p><a href="http://www.beian.miit.gov.cn/#/">京ICP备18047705号</a></p>
	  			<p></p>
	  			<p>© 2019-2020 MTGso.cn 版权所有</p>
	  		</div>
	  		<div className="right-navi-container">
	  			<nav id="web-page-navigation">
					<Space>
						<a href="">网站帮助</a>
						<a href="">网站地图</a>
					</Space>
				</nav>
	  		</div>
	  	</div>
		)
	}
}

	class UserCenterBox extends React.Component{
		constructor(props){
			super(props);
			var userData = JSON.parse(localStorage.getItem('userData')||sessionStorage.getItem('userData'));
			this.state={
				s_id : userData?userData["session-id"]:null,
				username : userData?userData["username"]:"请登录",
				userIcon: "http://img.mtgso.cn/web_images/symbol/rarity/mr.png",
				isLoading : false,
			}

		};
		checkIfLogin(){
			this.setState({
				username:"abc",
				userIcon:null,
			})
		};
		redirectTo(url){
			window.location.href=url;
		}
		render(){
			const userIcon = this.state.userIcon;
			const username = this.state.username;
			const a1_list = ["/page1.html","/user-center.html","/single-collection.html","/deck-collection.html","/trading-list.html","/deck-edit.html"];
			return(
				<div id="user-center-wrapper">
					<div id="user-center-inner-wrapper">
						<div id="user-info"
						  onClick={()=>{this.redirectTo(a1_list[1])}}
						>
							{username?(
								<>
									<a id="user-icon" href={a1_list[1]}>
										<img src={userIcon} alt="" />
									</a>
									<div id="user-id">{username}</div>
								</>
							):(
								<>
									<a id="user-icon" href={a1_list[0]}>
										<img src={"http://img.mtgso.cn/web_images/symbol/mana_circle.png"} alt="" />
									</a>
									<div id="user-id">请登录</div>
								</>
							)}
						</div>
						<div id="user-center-menu">
							<div className="menu-line"></div>
							<a className="menu" href={a1_list[2]}>单卡收藏</a>
							<a className="menu" href={a1_list[3]}>套牌收藏</a>
							<a className="menu" href={a1_list[4]}>卡牌清单</a>
							<a className="menu" href={a1_list[5]}>我的套牌</a>
						</div>
					</div>
				</div>
			)
		}
	}

class CenterLayoutIndexPage extends React.Component{
	constructor(props){
		super(props);
	}
	render(){
		return(
		<div id="main">
			<div id="logo-wrapper">
				<div id="logo-container">
					<img src={require("../../img/logo_0918.png")} alt="" />
				</div>
			</div>
			<div id="searchbox-wrapper">
				<SearchInputBox/>
			</div>
			<div id="advance-link-wrapper">
				<a id="advance-link" href="">高级搜索</a>	
			</div>
		</div>
		)
	}
}
	class SearchInputBox extends React.Component{
		constructor(props){
			super(props);
			this.state={
				input:"",
				associateList:[],
				boxOpen:false,
				isLoadingBox:false,
			}
		}
		//根据输入内容变化调整联想盒子
		async getAssociate(val){
			this.setState({
				input:val,
			});
			if(!this.state.associateList.length){this.setState({boxOpen:false})}
			if(!val){this.closeBox();return;}
			this.setState({
				isLoadingBox:true,
			})
			const req = await getCardnameAssociate(val);
			this.setState({
				associateList:req.payload?req.payload.cards:[],
				isLoadingBox:false,
				boxOpen:true
			})
		}
		//提交输入并跳转到搜索结果页面
		onSearchSubmit(val){
			if(val){window.location.href="../single-search-result/single-search-result.html?printed_name="+val;}
		}
		//如果输入中有内容，打开联想盒子
		openBox(val){
			if(val){this.getAssociate(val)}
		}
		//关闭联想盒子
		closeBox(){
			this.setState({
				associateList:[],
				boxOpen:false
			})
		}
		//构建联想盒子nav
		buildAutoCompleteList = () => {
			const associateList = this.state.associateList;
			const framework = [];
			const chooseColor = (arr) => {
				const icons = {
					W:"rgba(254,253,196)",
					U:"rgba(138,211,250)",
					B:"rgba(112,95,93)",
					R:"rgba(245,138,110)",
					G:"rgba(121,204,145)",
					C:"rgba(194,179,177)",
					M:"rgba(200,165,71)",
				}
				function decidemode(arr){
					switch (arr.length) {
						case 0:
							return icons.C;
						case 1: 
							return icons[arr[0]]
						default:
							return icons.M
					}
				}
				return(
					<svg>
						<circle cx="50%" cy="50%" r="40%" fill={decidemode(arr)}/>
					</svg>
				)
			}
			function copyCardLink(card_id){
				const val = "http://pages/card/card.html?id="+card_id;
				var $temp = $("<input>");
				$("body").append($temp);
				$temp.val(val).select();
				document.execCommand("copy");
				$temp.remove();
				alert("已复制小程序链接到剪切板");
			}
			function copyCardName(card_name){
				const val = card_name;
				var $temp = $("<input>");
				$("body").append($temp);
				$temp.val(val).select();
				document.execCommand("copy");
				$temp.remove();
				alert("已复制牌名到剪切板");
			}
			for (let i = 0; i < associateList.length; i++) {
				const card = associateList[i];
				framework.push(
					<div className="autocomplete-link-wrapper" key={"autocomplete-item-"+i}>
						{chooseColor(card.colors)}
						<a className="autocomplete-link" href={"/single-card-detail.html?id="+card.id}>{card.name_cn}</a>
						<div className="autocomplete-copy-button" onClick={()=>{copyCardName(card.name_cn)}}>复制</div>
					</div>
				);
			}
			if(!associateList.length && !this.state.isLoadingBox){
				return(
					<div 
					id="AutoCompleteBox"
					className="autocomplete"
					style={{position:"absolute"}}
					>
						<div id="AutoCompleteList" className="autocomplete-list">
							<p style={{margin:0}}>没找到相关单卡呢...</p>
						</div>
					</div>
				)
			}
			return (
				<div 
				id="AutoCompleteBox"
				className="autocomplete"
				style={{position:"absolute"}}
				>
					<div id="AutoCompleteList" className="autocomplete-list">
						{framework}
					</div>
				</div>	
			)
		}
		render(){
			return(
				<>
					<div 
					className={this.state.boxOpen?"searchbox activated":"searchbox"}>
						<span className="searchIcon" onClick={()=>this.onSearchSubmit(this.state.input)}>
							<SearchOutlined/>
						</span>
						<input 
							id="name" 
							autoComplete="off"
							value={this.state.input}
							onKeyDown={(e)=>{
								if(e.keyCode==13){
									this.onSearchSubmit(e.target.value)
								}
							}}
							onChange={(e)=>this.getAssociate(e.target.value)}
							onFocus={(e)=>this.openBox(e.target.value)}
							onClick={(e)=>{e.target.select()}}
						/>
						{this.state.input?(
							<span className="closeIcon" onClick={()=>this.setState({input:""})}>
								<CloseCircleOutlined/>
							</span>
						):null}
						{this.state.boxOpen?(
							<this.buildAutoCompleteList />
						):null}
					</div>
				</>
			);
		}
	}

export {CenterLayoutHeader, CenterLayoutFooter, CenterLayoutIndexPage};