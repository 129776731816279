import React from 'react';
import './App.css';

import { BrowserRouter as Router,Route, Redirect} from 'react-router-dom';
import {Switch} from 'react-router-dom';

// import UserAccount_Page_LoginPage from '../page1/App';
// import UserAccount_Page_RegisterPage from '../page2/App';
// import UserAccount_Page_BindingNewWechatPage from '../page3/App';
// import UserCenter_Page from '../user-center/App';
// import UserCenter_Page_DeckCollection from '../deck-collection/App';
// import UserCenter_Page_SingleCollection from '../single-collection/App';

import {CenterLayoutHeader, CenterLayoutFooter, CenterLayoutIndexPage} from '../js/main-page/CenterLayout';
import '../css/headbar-footbar.css';
import '../css/center-layout.css';
import '../css/search-box.css';

function App() {
  // function addRoute(){
  //   const RouteList = ["/page1","/page2","/page3","/user-center","/deck-collection","/single-collection"];
  //   const frame = [];

  //   for (let i = 0; i < RouteList.length; i++) {
  //     const element = RouteList[i];
      
  //   }
  //   frame.push(
  //     <Redirect exact from={RouteList[i]} to={RouteList[i]+".html"} />
  //   );
  // }
  return (
    <Router>
        <Switch>
          <Route exact path="/" component={Homepage} />
          {/* <Route exact path="/page1" component={UserAccount_Page_LoginPage} />
          <Route exact path="/page2" component={UserAccount_Page_RegisterPage} />
          <Route exact path="/user-center" component={UserCenter_Page } />
          <Route exact path="/deck-collection" component={UserCenter_Page_DeckCollection} />
          <Route exact path="/single-collection" component={UserCenter_Page_SingleCollection} /> */}
          {/* <Redirect from="/*" to="/" /> */}
        </Switch>
    </Router>
  );
}

class Homepage extends React.Component{
  render(){
    return(
    <div className="App col-layout">
      <CenterLayoutHeader />
	    <CenterLayoutIndexPage />
      <CenterLayoutFooter />
    </div>
    )
  }
}
  
export default App;
  